import { useTranslation } from 'next-i18next'

import { GridCol, GridRow, Surface } from '@paniclobster/komp'

import useWorkspacesSummary from '../../../../hooks/useWorkspacesSummary'

import LoadingContent from '../../../../components/LoadingContent/LoadingContent'

import WorkspacesSummaryWorkspaceCard from './WorkspacesSummaryWorkspaceCard/WorkspacesSummaryWorkspaceCard'

export default function WorkspacesSummary() {
  const { t: translateWorkspacesSummary } = useTranslation('workspacesSummary')

  const { isFetchingWorkspacesSummary, workspacesSummary } =
    useWorkspacesSummary()

  if (isFetchingWorkspacesSummary) {
    return (
      <GridCol span={24}>
        <Surface elevation={2} fullWidth padded>
          <LoadingContent size="large" />
        </Surface>
      </GridCol>
    )
  }

  return (
    <GridCol span={24}>
      <GridRow gutter={[8, 8]}>
        <GridCol span={24}>
          <Surface elevation={2} fullWidth padded>
            {translateWorkspacesSummary('text.workspaces')}
          </Surface>
        </GridCol>

        {workspacesSummary?.workspaces?.map((workspace) => (
          <GridCol key={`workspace-${workspace.id}`} span={24}>
            <WorkspacesSummaryWorkspaceCard workspace={workspace} />
          </GridCol>
        ))}
      </GridRow>
    </GridCol>
  )
}
