import { useTranslation } from 'next-i18next'

import { GridRow } from '@paniclobster/komp'

import RecoilProvider from '../../../providers/RecoilProvider/RecoilProvider'

import ApplicationLayout from '../../../components/ApplicationLayout/ApplicationLayout'

import WorkspacesSummary from './WorkspacesSummary/WorkspacesSummary'

export default function Workspaces() {
  const { t: translateWorkspaces } = useTranslation('workspaces')

  return (
    <RecoilProvider>
      <ApplicationLayout
        title={translateWorkspaces('index.title') ?? undefined}
      >
        <GridRow gutter={[0, 8]}>
          <WorkspacesSummary />
        </GridRow>
      </ApplicationLayout>
    </RecoilProvider>
  )
}
