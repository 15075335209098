import axios from 'axios'
import { useCallback, useMemo } from 'react'

import type { WorkspacesSummaryContract } from '@paniclobster/commons'

import { useQuery } from '@tanstack/react-query'

import { getApiUrl } from '../core/configs'

import {
  queryClient,
  useQueryOptions,
  // useQueryCallbackOptions,
} from '../helpers/query'

import type { BaseQueryOptions } from '../helpers/query'

export type UseWorkspaceSummaryOptions = BaseQueryOptions

export interface UseWorkspacesSummaryState {
  fetchWorkspacesSummary: () => Promise<WorkspacesSummaryContract | null>
  isFetchingWorkspacesSummary: boolean
  setWorkspacesSummary: (
    workspacesSummary: WorkspacesSummaryContract | null,
  ) => void
  workspacesSummary: WorkspacesSummaryContract | null
}

export default function useWorkspacesSummary(
  options: UseWorkspaceSummaryOptions = {},
): UseWorkspacesSummaryState {
  const queryOptions = useQueryOptions(options, { refetchOnWindowFocus: false })

  // TODO: Use these options
  // const queryCallbackOptions = useQueryCallbackOptions(options)

  const workspacesSummaryQueryKey = useMemo(() => ['workspaces', 'summary'], [])

  const workspacesSummaryQueryCallback = useCallback(async () => {
    const requestUri = getApiUrl('/workspaces/summary')
    const requestResponse = await axios
      .get<WorkspacesSummaryContract | null>(requestUri)
      .then((response) => response?.data ?? null)
      .catch((err) => {
        if (err?.response?.status === 404) {
          return null
        }

        throw err
      })
    return requestResponse
  }, [])

  const workspacesSummaryQuery = useQuery(
    workspacesSummaryQueryKey,
    workspacesSummaryQueryCallback,
    { ...queryOptions },
  )

  const fetchWorkspacesSummary = useCallback(
    async () => (await workspacesSummaryQuery.refetch()).data ?? null,
    [workspacesSummaryQuery],
  )

  const setWorkspacesSummary = useCallback(
    (workspacesSummary: WorkspacesSummaryContract | null) => {
      queryClient.setQueryData(
        workspacesSummaryQueryKey,
        () => workspacesSummary,
      )
    },
    [workspacesSummaryQueryKey],
  )

  return {
    fetchWorkspacesSummary,
    isFetchingWorkspacesSummary: workspacesSummaryQuery.isLoading,
    setWorkspacesSummary,
    workspacesSummary: workspacesSummaryQuery.data ?? null,
  }
}
