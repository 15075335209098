import { RecoilRoot } from 'recoil'

import type { ReactNode } from 'react'

export interface RecoilProviderProps {
  children?: ReactNode
}

export default function RecoilProvider(props: RecoilProviderProps) {
  const { children } = props

  return <RecoilRoot>{children}</RecoilRoot>
}
