import type { GetStaticPropsContext } from 'next'

import AuthenticationProvider from '../providers/AuthenticationProvider/AuthenticationProvider'

import { getTranslationProps } from '../helpers/i18n'

import WorkspacesIndex from '../application/workspaces/WorkspacesIndex/WorkspacesIndex'

export default function PageIndex() {
  return (
    <AuthenticationProvider authenticationRequired>
      <WorkspacesIndex />
    </AuthenticationProvider>
  )
}

export async function getStaticProps(context: GetStaticPropsContext) {
  return {
    props: {
      ...(await getTranslationProps(context, [
        'index',
        'workspaces',
        'workspacesSummary',
      ])),
    },
  }
}
