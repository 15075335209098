import { useMemo } from 'react'

import type { ImgHTMLAttributes } from 'react'

import { isStorageFile, isUploadFile } from '@paniclobster/commons'

import type {
  StorageFileContract,
  UploadFileContract,
} from '@paniclobster/commons'

type HTMLImageProps = ImgHTMLAttributes<HTMLImageElement>

export function useImageOrPlaceholder(
  image: StorageFileContract | UploadFileContract | HTMLImageProps['src'],
  placeholder: StorageFileContract | UploadFileContract | HTMLImageProps['src'],
) {
  const imageOrPlaceholder = useMemo(() => {
    if (typeof image === 'string' && image.length > 0) {
      return image
    }

    if (isUploadFile(image)) {
      return image
    }

    if (isStorageFile(image)) {
      return image
    }

    return placeholder
  }, [image, placeholder])

  return imageOrPlaceholder
}
