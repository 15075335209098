import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'

import type { WorkspaceContract } from '@paniclobster/commons'

import { WorkspaceCard } from '@paniclobster/komp'

import { useImageOrPlaceholder } from '../../../../../helpers/image'

import placeholderImageHeader from '../../../../../assets/images/placeholders/workspace/header.jpg'

export interface WorkspacesSummaryWorkspaceCardProps {
  workspace: WorkspaceContract
}

export default function WorkspacesSummaryWorkspaceCard(
  props: WorkspacesSummaryWorkspaceCardProps,
) {
  const { t: translateWorkspaces } = useTranslation('workspacesSummary')

  const { workspace } = props

  const router = useRouter()

  const headerImage = useImageOrPlaceholder(
    workspace.data?.header,
    placeholderImageHeader.src,
  )

  return (
    <WorkspaceCard
      cardAction={{
        color: 'primary',
        onClick: () => {
          router.push(`/w/${workspace.slug}`)
        },
        title: translateWorkspaces('actions.open'),
      }}
      elevation={2}
      fullWidth
      image={headerImage}
      onClick={() => {}}
      title={workspace.title}
      variant="horizontal"
    />
  )
}
